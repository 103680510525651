.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: $input-padding;
  border: none;
  border-radius: $border-radius-sm;
  background-color: $c-primary;
  color: $c-white;
  font-size: 15px;
  font-weight: 600;
  font-family: $primary-font;
  box-shadow: 0 5px 15px 0 rgba(59, 76, 184, 0.2);
  transition: opacity 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  text-decoration: none;
  cursor: outline;

  &.small {
    padding: 10px;
  }
  &.no-stretch {
    width: max-content;
  }

  &.outline {
    background-color: $c-white;
    color: $c-black;
    font-weight: 500;
    border: 1px solid rgba($color: $c-primary, $alpha: 0.3);
    box-shadow: none;

    &:hover {
      opacity: 1;
      background: $c-button-hover;
      color: $c-primary;
      text-decoration: none;
    }
    &:focus {
      color: $c-primary;
    }
  }

  &.with-icon {
    text-align: left;
    justify-content: space-between;
  }

  &:hover {
    color: $c-white;
    text-decoration: none;
    opacity: 0.88;
  }
  &:focus {
    color: $c-white;
  }
}
