.apartments-container {
  padding: 0;
  padding-top: 80px;
  padding-bottom: 80px;
  overflow-x: hidden;
  width: 100vw;
  max-width: 100vw;
  .title {
    text-align: center;
    h4 {
      font-size: 40px;
      font-weight: 900;
    }

    h6 {
      display: flex;
      justify-content: center;
      span {
        margin: 0 15px;
      }
    }
  }
  .image-row {
    position: relative;
    overflow-x: hidden;
    height: 170px;
    width: 100%;

    .image-content {
      position: absolute;
      display: flex;
    }

    .image {
      height: 150px;
      width: 250px;
      overflow: hidden;
      border-radius: 14px;
      margin: 10px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 14px;
      }
    }
  }
}

@media (min-width: $media-md) {
  .apartments-container {
    padding-top: 120px;
    .title {
      h4 {
        font-size: 50px;
      }

      h6 {
        span {
          margin: 0 15px;
        }
      }
    }
    .image-row {
      height: 190px;

      .image {
        height: 160px;
        width: 270px;
      }
    }
  }
}

@media (min-width: $media-lg) {
  .apartments-container {
    padding-top: 140px;
    .title {
      h6 {
        span {
          margin: 15px 25px;
        }
      }
    }
    .image-row {
      height: 220px;

      .image {
        height: 200px;
        width: 350px;
      }
    }
  }
}
