*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  min-width: 320px;
  overflow-y: scroll;
  overflow-x: hidden;
}

ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

// bootstrap icons
i[class^="bi-"] {
  font-size: 24px;
  margin: 0;
  line-height: 0;
}

.grabbing * {
  cursor: grabbing !important;
}
.resizing * {
  cursor: ew-resize !important;
}

.logo,
i {
  user-select: none;
}

// Smooth scroll
.container-fluid {
  width: 100vw;
}

@media (min-width: $media-md) {
  body {
  }
}

@media (min-width: $media-lg) {
}

@media (min-width: $media-xxl) {
}
