.amenities-container {
  padding: 0;
  padding-top: 80px;
  .amenties-title {
    padding: 15px;
    .title {
      font-weight: 900;
      color: $c-black;
      font-size: 40px;
      line-height: 40px;
    }

    .description {
      opacity: 0.7;
    }
  }

  .items {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    .item {
      position: relative;
      margin-bottom: 5px;
      padding-bottom: 55px;
      display: flex;
      justify-content: center;
      scroll-snap-align: center;

      .item-content {
        margin: 0 20px;
      }

      .item-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 15px;
        border-radius: 80px;
        height: 80px;
        cursor: pointer;
        width: 80px;
      }
      .item-label {
        position: absolute;
        font-family: $secondary-font;
        font-weight: 600;
        font-size: 15px;
        width: 100%;
        left: 0px;
        text-align: center;
        // bottom: 0;
        margin-top: 15px;
      }
    }
  }
}

.amenities-images-modal {
  position: fixed;
  z-index: 9999;
  right: 0;
  top: 0;
  transform: translateY(100%);
  width: 100vw;
  height: 100vh;

  background-color: $c-white;
  transition: transform 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86);

  &.show {
    transform: translateY(0%);
  }

  .content {
    position: relative;

    .header {
      padding: 20px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      .title {
        margin: 0;
        padding: 0;
        font-weight: 900;
        font-size: 30px;
      }

      .x {
        border: none;
        background: none;

        i {
          font-size: 30px;
        }
      }
    }

    .images {
      height: 100vh;
      overflow-y: scroll;
      padding: 20px;
      padding-bottom: 100px;

      .image {
        width: 100%;
        margin-bottom: 8px;

        img {
          padding: 0 4px;
          width: 100%;
        }
      }
    }
  }

  .masonryGrid {
    display: flex;
    // margin-left: -15px; /* gutter size offset */
    // padding-right: 15px;
    width: 100%;
  }
  .masonryColumn {
    // padding-left: 15px; /* gutter size */
    background-clip: padding-box;

    // customize here
    & > div {
      margin-bottom: 15px;
    }
  }
}

@media (min-width: $media-md) {
  .amenities-container {
    display: flex;
    align-items: center;

    .amenties-title {
      padding-left: 50px;
    }

    .items {
    }
  }

  .amenities-images-modal {
    transform: translateX(100%);
    transition: transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

    background-color: transparent;
    .content {
      float: right;
      width: 600px;
      background-color: $c-white;
    }

    &.show {
      transform: translateX(0%);
    }
  }

  .modal-bg {
    top: 0;
    left: 0;
    position: fixed;
    pointer-events: none;
    user-select: none;
    width: 100vw;
    height: 100vh;
    background-color: rgba($c-black, 0);
    z-index: 9;
    transition: background-color 200ms ease;
    &.show {
      pointer-events: all;
      background-color: rgba($c-black, 0.4);
    }
  }
}

@media (min-width: $media-lg) {
  .amenities-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 150px;

    .amenties-title {
      padding-left: 50px;
      max-width: 400px;
    }

    .items {
      overflow-x: visible;
      scroll-snap-type: unset;

      .item {
        transition: transform 150ms cubic-bezier(0.39, 0.575, 0.565, 1);
        .item-content {
          margin: 0 10px;
        }

        &:hover {
          transform: translateY(10px);

          .item-label {
            opacity: 1;
            transform: translateY(5px);
          }

          .item-icon {
            box-shadow: 0 10px 20px 10px rgba($c-black, 0.1);
          }
        }

        .item-icon {
          transition: all 150ms cubic-bezier(0.39, 0.575, 0.565, 1);
          transform: scale(0.9);
        }

        .item-label {
          font-size: 18px;
          transform: translateY(-5px);
          opacity: 0;
          transition: all 150ms cubic-bezier(0.39, 0.575, 0.565, 1);
        }
      }
    }
  }
}

@media (min-width: $media-xl) {
  .amenities-container {
    .amenties-title {
      padding-left: 50px;
      max-width: 500px;
      .title {
        font-size: 60px;
        line-height: 60px;
      }
    }

    .items {
      .item {
        .item-content {
          margin: 0 15px;
        }

        .item-icon {
          transform: scale(1);
        }
      }
    }
  }
}
