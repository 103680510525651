.container.services-container {
  padding-top: 20px;

  .inquire-holder {
    display: flex;
    justify-content: center;
    margin: 60px 0 20px 0;
  }
  .inquire {
    font-size: 18px;
    padding: 20px 50px 18px 50px;
    background: $c-primary;
    border: none;
    border-radius: 40px;
    color: $c-white;
    font-family: $secondary-font;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    box-shadow: 0 4px 16px 8px rgba($c-primary, 0.2);
  }
}

.service-compare-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  height: max-content;
  user-select: none;
  border-radius: 14px !important;

  overflow: hidden;

  .service-compare {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: inherit;
    max-height: inherit;
    border-radius: 12px;

    &.animate {
      .service.service-furnished {
        transition: width 450ms cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      .thumb {
        transition: margin 450ms cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      .service-list {
        transition: margin 450ms cubic-bezier(0.645, 0.045, 0.355, 1),
          opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    .service {
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      &.service-furnished {
        position: absolute;
        z-index: 1;
        height: 100%;
        max-height: inherit;
        background: white;

        img {
          user-select: none;
          height: 100%;
          width: 100%;
          max-height: inherit;
          object-fit: cover;
          object-position: left;
        }
        .gradient,
        img {
          border-top-left-radius: 14px;
          border-bottom-left-radius: 14px;
        }
      }

      &.service-unfurnished {
        max-height: inherit;
        border-radius: 14px;
        .gradient,
        img {
          border-radius: 14px;
        }
      }

      img {
        cursor: pointer;
        user-select: none;
        height: 100%;
        width: 100%;
        max-height: inherit;
        object-fit: cover;
        // transition: opacity 200ms cubic-bezier(0.645, 0.045, 0.355, 1);

        // &:hover {
        //   opacity: 0.9;
        // }
      }
      &:hover {
        .gradient {
          opacity: 0.7 !important;
        }
      }

      .gradient {
        user-select: none;
        pointer-events: none;

        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        max-height: inherit;
        width: 100%;
        opacity: 0.8;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.5) 30%,
          rgba(0, 0, 0, 0.3) 70%,
          rgba(0, 0, 0, 0.5) 100%
        );
        transition: opacity 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    .thumb {
      position: absolute;
      z-index: 5;
      cursor: ew-resize;
      height: 100%;
      background: white;
      width: 4px;
      left: 0;
      margin-left: -2px;
      user-select: none;

      &:active,
      &:focus {
        .thumb-icon {
          box-shadow: 0 4px 8px 4px rgba(#000, 0.15);
          span {
            opacity: 0.6;
          }
        }
      }

      .thumb-icon {
        position: relative;
        width: 34px;
        height: 34px;
        top: calc(50% - 17px);
        left: -15px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        transition: box-shadow 200ms cubic-bezier(0.645, 0.045, 0.355, 1);

        span {
          margin-top: -2px;
          opacity: 0.4;
        }
        &:hover {
          cursor: ew-resize;
          box-shadow: 0 4px 8px 4px rgba(#000, 0.05);

          span {
            opacity: 0.5;
          }
        }
      }
    }

    .service-lists {
      position: absolute;
      width: 100vw;
      max-width: inherit;
      margin: 0 auto;
      // left: -15px;
      height: 100%;
      top: 0;
      pointer-events: none;
      overflow: hidden;
    }
    .service-list {
      position: absolute;
      z-index: 3;
      width: 100%;
      top: 0;
      transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
      max-width: 80%;
      height: 100%;

      &.furnished {
        left: 0%;
        padding-left: 5px;
        .content {
          .ul {
            li {
              font-family: $secondary-font;
              font-size: 17px;
              // margin-right: 20px;
            }
          }
        }
      }

      &.unfurnished {
        text-align: right;
        right: 0;
        padding-right: 35px;
        .content {
          float: right;

          .ul {
            li {
              margin-left: 20px;
            }
          }
        }
      }

      .content {
        padding: 12px 10px;
        width: max-content;
        user-select: text;
        pointer-events: none;
        color: $c-white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .title {
          font-weight: 600;
        }

        .ul {
          max-width: 60vw;
          li {
            margin-top: 5px;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .select-tooltip {
    z-index: 6;
    right: 0;
    position: absolute;
    text-align: right;
    font-weight: 500;
    max-width: 100vw;
    padding: 2px 14px 5px 14px;
    color: rgba($c-black, 0.8);
    font-size: 13px;
    background-color: rgba(#fff, 1);
    margin-bottom: 10px;
    box-shadow: 0 4px 8px 4px rgba(#000, 0.05);
    border-bottom-left-radius: 8px;
  }
}

@media (min-width: $media-sm) {
  .service-compare-container {
    .service-compare {
      .service-lists {
        height: 100%;
        display: flex;
        align-items: flex-end;
        .title {
          font-size: 30px;
        }
        .service-list {
          position: absolute;
          z-index: 3;
          margin-top: 0;
          height: 100%;
          // top: unset;

          .content {
            position: relative;
            background: none;
            box-shadow: none;
            color: $c-white;
            width: 100%;
            pointer-events: none;
            padding: 10px 20px 20px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            .title {
              color: $c-white;
              font-size: 40px;
              padding-top: 5px;
              max-width: max-content;
              pointer-events: none;
            }

            .ul {
              bottom: 0;
              display: block;
              max-width: 100%;
            }
            .ul li {
              margin-top: 7px;
              pointer-events: none;
            }
          }

          &.furnished {
            // left: 0;
          }

          &.unfurnished {
            text-align: right;
            left: unset;
            right: 0;
            padding-right: 30px;
            .content {
              display: flex;
              flex-direction: column;
              // justify-content: flex-end;
              .title {
                max-width: 100%;
              }
              .ul {
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $media-md) {
  .service-compare-container {
    max-width: 700px;
    .service-compare {
      .service-lists {
        .service-list {
          .content {
            padding: 20px 25px 20px 20px;

            .title {
              font-size: 45px;
            }
          }

          &.unfurnished {
            text-align: right;
            left: unset;
            right: 0;
            padding-right: 0px;
          }
        }
      }
    }
  }
}

@media (min-width: $media-xl) {
  .service-compare-container {
    padding-top: 30px;
    max-width: 800px;
    overflow: visible;

    .service-compare {
      .service-lists {
        .service-list {
          .content {
            padding: 30px 35px 30px 30px;

            .title {
              font-size: 55px;
            }

            .ul li {
              font-size: 22px;
            }
          }
        }
      }
    }

    // .service-compare {
    //   overflow: visible;
    //   .service-lists {
    //     overflow: visible;
    //     .service-list {
    //       overflow: visible;
    //       .content {
    //         justify-content: center;
    //         z-index: -1;
    //         padding: 0;
    //         color: $c-black;
    //         padding-top: 15px;

    //         width: 180px;
    //         .title {
    //           color: $c-primary;
    //           font-size: 33px;
    //         }

    //         .ul li {
    //           font-family: $secondary-font;
    //         }
    //       }

    //       &.furnished {
    //         text-align: right;
    //         // left: 0;
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: flex-end;
    //         transform: translateX(-32%);
    //         .title {
    //           margin-right: -30px;
    //         }
    //       }

    //       &.unfurnished {
    //         text-align: left;
    //         left: unset;
    //         right: 0;
    //         padding-right: 30px;
    //         transform: translateX(32%);
    //         .content {
    //           display: flex;
    //           flex-direction: column;
    //           // justify-content: flex-end;
    //           .title {
    //             margin-left: 17px;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
}

@media (min-width: $media-xxl) {
  .service-compare-container {
    padding-top: 40px;
    max-width: 900px;

    // .service-compare {
    //   .service-lists {
    //     .service-list {
    //       .content {
    //         width: 200px;
    //         .title {
    //           color: $c-primary;
    //           font-size: 47px;
    //           letter-spacing: -0.5px;
    //         }
    //         .ul li {
    //           font-size: 15px;
    //         }
    //       }

    //       &.furnished {
    //         text-align: right;
    //         // left: 0;
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: flex-end;
    //         transform: translateX(-32%);
    //         .title {
    //           margin-left: -48px;
    //         }
    //       }

    //       &.unfurnished {
    //         text-align: left;
    //         left: unset;
    //         right: 0;
    //         padding-right: 30px;
    //         transform: translateX(32%);
    //         .content {
    //           display: flex;
    //           flex-direction: column;
    //           // justify-content: flex-end;
    //           .title {
    //             margin-left: 17px;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
}

@media (min-width: 1600px) {
  .service-compare-container {
    padding-top: 40px;
    max-width: 900px;

    // .service-compare {
    //   .service-lists {
    //     .service-list {
    //       .content {
    //         .title {
    //           font-size: 52px;
    //         }
    //       }

    //       &.furnished {
    //         .title {
    //           margin-left: -76px;
    //         }
    //       }

    //       &.unfurnished {
    //         .content {
    //           .title {
    //             margin-left: 17px;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
}
