// ABSTRACT
@import "./abstract/imports";
@import "./abstract/variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";

// BASE
@import "./base/base";
@import "./base/buttons";

// COMPONENTS
@import "./components/navbar";
@import "./components/hero";
@import "./components/services";
@import "./components/amenities";
@import "./components/apartments";
@import "./components/contact";
