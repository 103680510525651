@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,400;1,500&display=swap");

// Girloy
@font-face {
  font-family: "Gilroy";
  src: url(./../../fonts/Gilroy/Gilroy-Bold.otf);
  font-weight: 900;
}

@font-face {
  font-family: "Gilroy";
  src: url(./../../fonts/Gilroy/Gilroy-Semibold.otf);
  font-weight: 600;
}
@font-face {
  font-family: "Gilroy";
  src: url(./../../fonts/Gilroy/Gilroy-Medium.otf);
  font-weight: 500;
}
@font-face {
  font-family: "Gilroy";
  src: url(./../../fonts/Gilroy/Gilroy-Light.otf);
  font-weight: 300;
}
@font-face {
  font-family: "Gilroy";
  src: url(./../../fonts/Gilroy/Gilroy-RegularItalic.otf);
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url(./../../fonts/Gilroy/Gilroy-Regular.otf);
}
