.navbar-container {
  position: fixed;
  margin: 0;
  padding: 0;
  min-width: 320px;
  z-index: 9;

  .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .desktop {
    display: none;
  }

  .mobile {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transform: translateY(0);

    &.up {
      transform: translateY(-80px);
    }

    &.background {
      background-color: white;
      border-bottom: 1px solid rgba(#000, 0.05);
    }
    .logo {
      display: block;
      margin-top: 5px;
      width: 95px;
      object-fit: contain;

      &.menu-shown {
        filter: invert(1);
      }
    }
  }

  .mobile-menu-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .mobile-menu-button {
      background: none;
      border: none;
      display: inline-block;

      .bar1,
      .bar2,
      .bar3 {
        display: block;
        width: 24px;
        height: 2px;
        border-radius: 2px;
        background-color: #000;
        margin: 6px 0;
        transition: 200ms cubic-bezier(0.23, 1, 0.32, 1);
      }

      &.shown {
        .bar1,
        .bar2,
        .bar3 {
          background: #fff;
        }
        .bar1 {
          transform: rotate(-45deg) translate(-4px, 4px);
        }

        .bar2 {
          transform: translateX(-8px);
          opacity: 0;
        }

        .bar3 {
          transform: rotate(45deg) translate(-7px, -8px);
        }
      }
    }
  }

  .mobile-menu {
    position: fixed;
    z-index: -1;
    top: 0;
    height: 100vh;
    width: 100vw;
    transform: translateY(-100vh);
    overflow-y: scroll;

    transition: transform 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #000;
    color: #fff;
    text-align: center;
    padding-bottom: 200px;

    .list {
      a,
      button {
        font-weight: 600;
        font-size: 40px;
        font-family: $secondary-font;
        color: #fff;
        background: none;
        border: none;
        text-decoration: none;

        &:hover {
          opacity: 0.6;
        }
      }

      &.quick-menu {
        transform: translateY(-200px);

        transition: transform 1300ms cubic-bezier(0.645, 0.045, 0.355, 1);
        padding-bottom: 80px;

        li {
          margin: 10px 0;
          transition: transform 500ms cubic-bezier(0.33, 1, 0.68, 1);
          transform: translateY(0);
        }
      }
    }

    &.shown {
      transform: translateY(0);

      .list {
        transform: translateY(10vh);

        &.quick-menu {
          li {
            transform: translateY(50px);
          }
        }
      }
    }
  }
}

@media (min-width: $media-lg) {
  .navbar-container {
    position: absolute;
    .desktop {
      display: flex;
      align-items: center;
      padding: 15px 30px 0 30px;

      .logo img {
        width: 120px;
      }

      .navbar-items {
        width: 100%;
        left: 0;
        position: absolute;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 00px;

        .item {
          margin: 0 30px;

          a,
          button {
            font-weight: 500;
            font-family: $secondary-font;
            font-size: 18px;
            font-weight: 600;
            border: none;
            background: none;
            display: block;
            padding: 10px;

            color: $c-black;
            text-decoration: none;
            transition: color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);

            &:hover {
              color: $c-primary;
            }
          }
        }
      }
      // .login {
      //   position: relative;
      //   width: 70px;

      //   .login-button {
      //     width: 100%;
      //     padding: 14px 30px 12px 30px;
      //     margin-left: -40px;
      //     background-color: rgba($c-white, 0.5);
      //     border-radius: 8px;
      //     font-weight: 600;
      //     font-size: 18px;
      //     font-family: $secondary-font;

      //     color: $c-primary;
      //     text-decoration: none;
      //     transition: background-color 100ms
      //       cubic-bezier(0.645, 0.045, 0.355, 1);

      //     &:hover {
      //       background-color: rgba($c-white, 0.8);
      //     }
      //   }
      // }
    }

    .mobile {
      display: none;
    }
  }
}
